const dev = {
	API_ENDPOINT_URL: 'https://localhost:8000/v1/',
	IMG_SRC: 'https://localhost:8000',
	APP_URL: 'https://localhost:3000'
};

const prod = {
	API_ENDPOINT_URL: 'https://apitest.tunimeet.com/v1/',
	IMG_SRC: 'https://apitest.tunimeet.com',
	APP_URL: 'https://admin.tunimeet.com'
};

const test = {
	API_ENDPOINT_URL: 'https://api.promobut.com/v1/',
	IMG_SRC: 'https://localhost:8000/images/products/',
	APP_URL: 'https://localhost:3000'
};


const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case "development":
			return dev;
		case "production":
			return prod;
		case "test":
			return test;
		default:
			break;
	}
};

export const env = getEnv();

import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, message } from 'antd';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const { TextArea } = Input;

const AiModel = ({ onSaveDiscussion, updateDiscussionRef, meetingCreator, username }) => {
    const [discussion, setDiscussion] = useState('');
    const [hide, setHide] = useState(false);

    const discussionRef = useRef(discussion);


    const {
        transcript,
        listening,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    useEffect(() => {
        discussionRef.current = discussion;
    }, [discussion]);

    // Start listening as soon as the component mounts
    useEffect(() => {
        SpeechRecognition.startListening({ continuous: true });
    }, []);

    useEffect(() => {
        updateDiscussionRef(transcript);
    }, [transcript, updateDiscussionRef]);

    const handleHide = () => {
        setHide(!hide);
    }

    const handleSaveDiscussion = (text) => {
        setDiscussion(text);
        onSaveDiscussion(text); // Call the callback function
    }

    if (!browserSupportsSpeechRecognition) {
        message.error({
            content: 'Your browser does not support speech recognition',
            duration: 2
        });
        return <></>;
    }

    return (
        <>
            {meetingCreator === username && hide && (
                <div>
                    <TextArea
                        value={transcript}
                        className='bg-white position-absolute ml-4'
                        style={{ width: '15rem', top: '25vh', height: '50%' }}
                    />
                </div>
            )}
            {meetingCreator === username && (
                <div className='position-absolute ml-4' style={{ top: '80vh' }}>
                    <p className='text-white'>AI detector: {listening ? 'on' : 'off'}</p>
                    <Button className='mr-1' onClick={handleHide}>
                        {hide ? 'Hide' : 'Show'} AI model
                    </Button>
                    {hide && (
                        <>
                            <Button className='mr-1' onClick={() => handleSaveDiscussion(transcript)}>
                                Save
                            </Button>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default AiModel;
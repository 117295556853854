import fetch from "auth/FetchInterceptor";
import { signOut } from "redux/actions/Auth";

const JwtAuthService = {};

function refreshToken() {
  return fetch({
    url: "/token/refresh",
    method: "GET",
  });
}

JwtAuthService.refreshToken = function Ref(dispatch) {
  refreshToken().then((resp) => {
    if (resp === 401) {
      dispatch(signOut());
    }
  }).catch(error => {
    console.error("Error refreshing token:", error);
  });
};
JwtAuthService.login = function (data) {
  return fetch({
    url: "/login_check",
    method: "POST",
    data: data,
  });
};

JwtAuthService.signOut = function () {
  return fetch({
    url: "/user/logout",
    method: "GET",
  });
};

JwtAuthService.signUp = function (data) {
  return fetch({
    url: "/user/signup",
    method: "POST",
    data: data,
  });
};

export default JwtAuthService;